<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="messageNotice-container">
    <!-- <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb> -->
    <div class="messageNotice-content">
      <div class="content-left">
        <el-tabs class="tabsClass" v-model="activeName" @tab-click="tabsFun">
            <el-tab-pane name="0">
              <span slot="label">
                <el-badge v-if="isReadNum.c0 != 0" :value="isReadNum.c0" :max="99">
                  <span>
                    全部
                  </span>
                </el-badge>
                <span v-else>
                  全部
                </span>
              </span>
            </el-tab-pane>
          <el-tab-pane name="1">
            <span slot="label">
              <el-badge v-if="isReadNum.c1 != 0" :value="isReadNum.c1" :max="99">
                <span>公告</span>
              </el-badge>
              <span v-else>
                  公告
                </span>
            </span>
          </el-tab-pane>
          <el-tab-pane name="2">
            <span slot="label">
              <el-badge v-if="isReadNum.c2 != 0" :value="isReadNum.c2" :max="99">
                <span>提醒</span>
              </el-badge>
              <span v-else>
                  提醒
                </span>
            </span>
          </el-tab-pane>
          <el-tab-pane name="3">
            <span slot="label">
              <el-badge v-if="isReadNum.c3 != 0" :value="isReadNum.c3" :max="99">
                <span>预警</span>
              </el-badge>
              <span v-else>
                  预警
                </span>
            </span>
          </el-tab-pane>
          <el-tab-pane name="4">
            <span slot="label">
              <el-badge v-if="isReadNum.c4 != 0" :value="isReadNum.c4" :max="99">
                <span>日报</span>
              </el-badge>
              <span v-else>
                  日报
                </span>
            </span>
          </el-tab-pane>
            <el-tab-pane name="5">
            <span slot="label">
              <el-badge v-if="isReadNum.c5 != 0" :value="isReadNum.c5" :max="99">
                <span>工单</span>
              </el-badge>
              <span v-else>
                  工单
                </span>
            </span>
          </el-tab-pane>
        </el-tabs>
        <div class="table-container" v-if="tableData && tableData.length">
          <div v-for="(item, index) in tableData" :key="item.date">
            <div class="message-item">
              <div class="message-date">
                <div>{{ item.date }}</div>
                <div v-if="index == 0 && isReadNum[`c${activeName}`] > 0" @click="() => { allRead() }" class="read-btn">一键已读</div>
                <div v-if="index == 0 && isReadNum[`c${activeName}`] <= 0" class="read-btn" style="background: #fff;color: #999;border-color: #eaeaea">一键已读</div>
              </div>
              <div :class=" itemList.id == nowChooseId ? 'messageItem messageItemActive' : 'messageItem'" v-for="(itemList, indexTwo) in item.list" :key="indexTwo" @click=" () => { chooseFun(itemList)} ">
                <div class="message-tip" style="background: #52c41a;color: #fff;" v-if="itemList.type == 1">公告<span class="isRead" v-if="itemList.is_read == 0"></span></div>
                <div class="message-tip" style="background: #ffbb5d;color: #fff;" v-if="itemList.type == 2">提醒<span class="isRead" v-if="itemList.is_read == 0"></span></div>
                <div class="message-tip" style="background: #F98787;color: #fff;" v-if="itemList.type == 3">预警<span class="isRead" v-if="itemList.is_read == 0"></span></div>
                <div class="message-tip" style="background: #F08009;color: #fff;" v-if="itemList.type == 4">日报<span class="isRead" v-if="itemList.is_read == 0"></span></div>
                <div class="message-tip" style="background: #1890ff;color: #fff;" v-if="itemList.type == 5">工单<span class="isRead" v-if="itemList.is_read == 0"></span></div>
                <el-tooltip v-if="itemList.type == 1 || itemList.type == 3 || itemList.type == 4 || itemList.type == 5" :open-delay="1000" class="item" effect="dark" :content="itemList.advice_title" placement="bottom">
                    <span class="message-title">{{ itemList.advice_title }}</span>
                </el-tooltip>
                <el-tooltip v-if="itemList.type == 2 && !itemList.advice_title" class="item" effect="dark" :open-delay="1000" :content="itemList.content" placement="bottom">
                    <span class="message-title">{{ itemList.content }}</span>
                </el-tooltip>
                <el-tooltip v-if="itemList.type == 2  && itemList.advice_title" class="item" effect="dark" :open-delay="1000" :content="itemList.advice_title" placement="bottom">
                    <span class="message-title">{{ itemList.advice_title }}</span>
                </el-tooltip>
                 <span class="message-time">{{ itemList.create_time }}</span>
                <!-- <span class="message-time" v-if="itemList.type == 2">{{ itemList.create_time }}</span>
                <span class="message-time" v-if="itemList.type == 3">{{ itemList.create_time }}</span>
                <span class="message-time" v-if="itemList.type == 4" >{{itemList.create_time}}</span> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else class="nothing" style="">暂无消息</div>
      </div>
      <div class="content-right">
        <!-- <div v-if="messageInfo.type == 1" class="title">{{ messageInfo.advice_title || '--' }}</div>
        <div v-if="messageInfo.type == 2" class="title">{{ messageInfo.content || '--' }}</div> -->
        <div v-if="messageInfo.type == 1 || messageInfo.type == 3 || messageInfo.type == 4 || messageInfo.type == 5" class="YJ-title">
          <el-tooltip class="item" effect="dark" :content="messageInfo.advice_title" placement="bottom">
              <div style="max-width: 70%;">{{ messageInfo.advice_title || "--" }}</div>
          </el-tooltip>
          <span>{{ messageInfo.create_time || "" }}</span>
        </div>
        <div v-if="messageInfo.type == 2 && (messageInfo.sendMsgType != 1 && messageInfo.sendMsgType != 2 && messageInfo.sendMsgType != 3)" class="YJ-title">
          <el-tooltip class="item" effect="dark" :content="messageInfo.content" placement="bottom">
              <div style="max-width: 70%;">{{ messageInfo.content || "--" }}</div>
          </el-tooltip>
          <span>{{ messageInfo.create_time || "" }}</span>
        </div>
        <div v-if="messageInfo.type == 2 && (messageInfo.sendMsgType == 1 || messageInfo.sendMsgType == 2 || messageInfo.sendMsgType == 3)" class="YJ-title">
          <el-tooltip class="item" effect="dark" :content="messageInfo.advice_title" placement="bottom">
              <div style="max-width: 70%;">{{ messageInfo.advice_title || "--" }}</div>
          </el-tooltip>
          <span>{{ messageInfo.create_time || "" }}</span>
        </div>
        <!-- <div v-if="messageInfo.type == 3" class="YJ-title">
          <div style="max-width: 70%;">{{ messageInfo.advice_title || "--" }}</div>
          <span>{{ messageInfo.create_time || "" }}</span>
        </div>
        <div v-if="messageInfo.type == 4" class="YJ-title">
          <div style="max-width: 70%;">{{ messageInfo.advice_title || "--" }}</div>
          <span>{{ messageInfo.create_time || "" }}</span>
        </div> -->
        <!-- <div v-if="messageInfo.type == 4" class="title">{{ '日报标题占位' || "--" }}</div> -->
        
        <div v-if="messageInfo.type == 1" class="content-detail">{{messageInfo.advice_content || "--" }}</div>
        <div v-if="messageInfo.type == 2 && (messageInfo.sendMsgType != 1 && messageInfo.sendMsgType != 2 && messageInfo.sendMsgType != 3)" class="content-detail">
          <div class="Detailitem">员工名称：{{messageInfo.adminInfo && messageInfo.adminInfo.nick_name || '--'}}</div>
          <div class="Detailitem">所属公司：{{messageInfo.adminInfo && messageInfo.adminInfo.company || '--'}}</div>
          <div class="Detailitem">所属部门：{{messageInfo.adminInfo && messageInfo.adminInfo.depart || '--'}}</div>
          <div class="Detailitem">角色：{{messageInfo.adminInfo && messageInfo.adminInfo.role || '--'}}</div>
        </div>
        <div v-if="messageInfo.type == 2 && (messageInfo.sendMsgType == 1 || messageInfo.sendMsgType == 2 || messageInfo.sendMsgType == 3)" class="content-detail">
          <div class="Detailitem">{{messageInfo.content || '--'}}</div>
          <div class="Detailitem" v-if="messageInfo.sendMsgType == 1 || messageInfo.sendMsgType == 2" @click="() => { openRoute(`/assign-order/assign-list?tabName=first&disOrderNo=${messageInfo.advice_content}&from=shopDetail`) }" style="color: #1890ff;cursor: pointer">查看详情 >></div>
          <div class="Detailitem" v-if="messageInfo.sendMsgType == 3" @click="() => { openRoute(`/customer/sales-leads?idName=${messageInfo.advice_content}&from=notice`) }" style="color: #1890ff;cursor: pointer">查看详情 >></div>
        </div>
        <div v-if="messageInfo.type == 3" class="YJ-record">
          <div v-for="(item, index) in chatHisList" :key="index" class="record-item" >
            <div class="recordTime">{{ item.msg_time }}</div>
            <div class="recordName"> {{ item.customer_nick == item.from_id ? item.customer_nick : item.from_id }} </div>
            <div :class=" item.customer_nick == item.from_id ? 'recordContent' : 'recordWarn' " > {{ item.msg_content }} </div>
          </div>
        </div>
        <div v-if="messageInfo.type == 4" class="content-detail">{{messageInfo.content || "--" }}</div>
        <div v-if="messageInfo.type == 5" class="content-detail">
           <div class="Detailitem">{{messageInfo.content || '--'}}</div>
          <div class="Detailitem" v-if="messageInfo.order_type == 1" @click="() => { openRoute(`/work-order/task?workOrderName=${messageInfo.order_name}&workOrderId=${messageInfo.order_id}`) }" style="color: #1890ff;cursor: pointer">查看详情 >></div>
          <div class="Detailitem" v-if="messageInfo.order_type == 2" @click="() => { openRoute(`/work-order/approve?workOrderName=${messageInfo.order_name}&workOrderId=${messageInfo.order_id}`) }" style="color: #1890ff;cursor: pointer">查看详情 >></div>
        </div>
      </div>
    </div>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { arrGroup } from "../../utils/index.js";
// import Breadcrumb from "../../components/common/breadcrumb.vue";
import { infoList, getChatHisByIds, readOk, allRead } from "../../service/common.js";
import { mapState } from "vuex"
export default {
  components: {
    // Breadcrumb,
  },
  computed: {
    ...mapState(["isReadNum", "comId"])
  },
  data() {
    return {
      chatHisList: [], // 聊天记录
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "个人中心", isLink: false }
      ],
      params: {
        pageNum: 1,
        pageSize: 10,
        type: 0
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      nowChooseId: this.$route.query.id,
      tableData: [],
      messageInfo: {},
      activeName: "0", // tabs
      readTotal: 0
    };
  },
  created() {
    this.infoList();
  },
  watch: {
    $route(to, from) {
      if (to.query.id != from.query.id && to.query.id != null) {
        //pid不为空才请求数据
        this.nowChooseId = to.query.id;
        this.activeName = "0";
        this.tabsFun();
        // this.infoList(); //调接口，请求数据
      }
    }
  },
  methods: {
    async allRead () {
      let { code } = await allRead({type: this.activeName, companyId: this.comId})
      if ( code == 200 ) {
        this.$message.success("已设置为已读")
        
        this.infoList()
      }
    },
    openRoute (url) { // 打开新标签，跳转派单详情
      window.open(url)
    },
    async getChatHisByIds(ids) {
      let resData = (await getChatHisByIds({ ids: ids })).data;
      this.chatHisList = resData;
      console.log(resData, "????");
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.infoList();
    },
    async chooseFun(row) {
      this.messageInfo = row;
      this.nowChooseId = row.id;
      if (row.type == 3) {
        this.getChatHisByIds(row.content);
      }
      await readOk({ infoId: row.id, type: row.type });
      // this.$vuex.dispatch("isReadNum");
      this.infoList();
    },
    async readOk (id, type) { // 已读/详情
      let resData = (await readOk({ infoId: id, type: type })).data
      this.messageInfo = resData
    },
    tabsFun() {
      console.log(this.activeName);
      this.params.type = this.activeName;
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.infoList();
    },
    async infoList() {
      this.loading = true;
      let params = { ...this.params };
      params.companyId = this.comId
      let resData = (await infoList(params)).data;
      this.$vuex.dispatch("isReadNum");
      let isId = false;
      resData.records.map((item) => {
        if (this.nowChooseId == item.id) {
          if (item.type == 3) {
            this.getChatHisByIds(item.content);
          } else if (item.type == 1 || item.type == 2 || item.type == 4 || item.type == 5) {
            this.readOk(item.id, item.type)
          }
          isId = true;
        }
      });
      if (resData.records && resData.records.length && !isId) {
        // this.messageInfo = resData.records[0] || {};
        if (resData.records[0].type == 3) {
          this.messageInfo = resData.records[0]
          this.getChatHisByIds(resData.records[0].content);
        } else if (resData.records[0].type == 1 || resData.records[0].type == 2 || resData.records[0].type == 4 || resData.records[0].type == 5) {
          this.readOk(resData.records[0].id, resData.records[0].type)
        }
        this.nowChooseId = resData.records[0].id;
        // this.getChatHisByIds(resData.records[0].content);
      }
      if (!resData.records || !resData.records.length) {
        this.messageInfo = {};
      }
      
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = arrGroup(resData.records);
      this.loading = false;
    }
  }
};
</script>
<style lang="less" scoped>
.messageNotice-container {

  background: #fff;
  height: 100%;
  color: #000000a6;
  /deep/.el-badge__content.is-fixed {
    top: 10px;
    // line-height: 16px;
    right: 6px;
  }
  .messageNotice-content {
    width: 100%;
    display: flex;
    padding: 0 20px;
    height: 90%;
    margin-bottom: 16px;
    box-sizing: border-box;
    .content-detail {
      padding: 20px;
      white-space: pre-line;
      .Detailitem {
        line-height: 32px;
      }
    }
    .content-left,
    .content-right {
      width: 50%;
      display: inline-block;
      border: solid 1px #eee;
      height: 100%;
      text-align: left;
      overflow-y: auto;
      .YJ-record {
        flex: 1;
        overflow-y: scroll;
        .record-item {
          padding: 0 18px;
          .recordTime {
            width: 160px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            border-radius: 4px;
            background: #f2f2f2;
            margin: 26px auto 0;
          }
          .recordWarn {
            margin: 12px 0;
            width: 700px;
            // height: 24px;
            line-height: 24px;
            // background: #F98787;
            border-radius: 4px;
            text-align: left;
            // color: #fff;
            color: #f98787;
            padding-left: 12px;
          }
          .recordContent {
            margin: 12px 0;
            width: 700px;
            // height: 24px;
            line-height: 24px;
            text-align: left;
            padding-left: 12px;
          }
          .recordName {
            text-align: left;
            color: #1890ff;
            padding-left: 14px;
            position: relative;
            margin-top: 12px;
            &::after {
              position: absolute;
              content: "·";
              color: #1890ff;
              left: 4px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }
      .YJ-detail {
        height: 325px;
        border-bottom: solid 1px #eee;
        padding: 18px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .detailItem {
          display: flex;
        }
      }
      .YJ-title {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        height: 45px;
        border-bottom: solid 1px #eee;
        cursor: pointer;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .title {
        height: 45px;
        border-bottom: solid 1px #eee;
        padding: 0 20px;
        line-height: 45px;
        text-align: left;
      }
    }
    .content-right {
      display: flex;
      flex-direction: column;
    }
    .content-left {
      border-right: none;
      overflow: auto;
      display: flex;
      flex-direction: column;
      .tabsClass {
        height: 45px;
        border-bottom: 1px solid #eee;
      }
      .table-container {
        flex: 1;
        overflow-y: scroll;
      }
    }
    .message-item {
      white-space: nowrap;
      .message-time {
        float: right;
        line-height: 40px;
      }
      .message-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        width: 70%;
      }
      cursor: pointer;
      margin-top: 16px;
      color: #000000a6;
      .message-date {
        font-size: 14px;
        color: #999;
        margin-top: 10px;
        padding: 0 22px;
        display: flex;
        justify-content: space-between;
        .read-btn {
          background: #1890ff22;
          color: #1890ff;
          border: 1px solid #1890ff;
          border-radius: 4px;
          padding: 6px 12px;
          font-size: 14px;
        }
      }
      .messageItem {
        padding: 5px 22px;
        margin: 10px 0;
        cursor: pointer;
        &:hover {
          background: #eee;
          border-left: 6px solid #ccc;
          padding-left: 16px;
        }
      }
      .messageItemActive {
        background: rgb(236, 242, 255);
        border-left: 6px solid #3477fd;
        padding-left: 16px;
      }
      .message-tip {
        font-size: 12px;
        color: #333;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        background: #bdbdbd;
        display: inline-block;
        margin-right: 16px;
        margin-bottom: 4px;
        margin-top: 4px;
        position: relative;
        .isRead {
          position: absolute;
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #f98787;
          right: -4px;
          top: -4px;
        }
      }
    }
  }
}
/deep/ .el-tabs__nav-wrap::after {
  display: none;
}
/deep/ .el-tabs__header .el-tabs__item {
  padding: 2px 24px 4px !important;
  height: 45px;
}
.nothing {
  text-align: center;
  padding: 200px 0;
  color: #999;
}
</style>
